const base = {
    login_path:'/dy_api/login',
    bd_chat: '/dy_api/bd-chat-single',
    azure_chat: '/dy_api/single-chat-process-a',
    grok_chat:'/dy_api/single-chat-process-grok',
    aliyun_ds_v3_chat:'/dy_api/single-chat-process-aliyun',
    yd_pic_translation: '/dy_api/yd-pic-content',
    yd_textranslation: '/dy_api/text-translation',
    bd_textTranslation: '/dy_api/bd-text-translation',
    bd_chat_single:'/dy_api/bd-chat-single',
    ali_pics_translation: '/dy_api/ali-pic-translate-direct',
    register:'/dy_api/register',
    sendEmail:'/dy_api/send-verification-code',
    forgetPwd: '/dy_api/forgot-password',
    resetPwd:'/dy_api/change-password',
    create_charge_qr_code:'/dy_api/create-charge',
    query_trade_result:'/dy_api/query-trade-package-duration',
    get_user_login_status_path:'/dy_api/get_user_login_status',
    get_user_profile_path:'/dy_api/profile',
    get_dy_fans_list_path:'/dy_api/get_fans_list',   //获取群列表
    get_dy_group_member_path:'/dy_api/get_group_member',  // 获取群成员
    get_dy_group_member_qr_path:'/dy_api/get_group_member_qrCode', // 获取qr_code
    get_dy_video_comment_path:'/dy_api/get_video_comment', // 获取视频最新评论
}

export default base