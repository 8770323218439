import axios from 'axios'
import base from './base'
import store from '@/store'
import router from '@/router'
import { Message } from 'element-ui';

// 定义获取最新 token 的函数
function getAccessToken() {
    return store.state.access_token;
  }
  
// 定义动态生成 header 的函数
function getHeaders() {
    const token = getAccessToken();
    return {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
  }

// 定义获取最新 refresh_token 的函数
function getRefreshToken() {
    return store.state.refresh_token;
  }
  
// 定义动态生成 header 的函数
function getRefreshHeaders() {
    const token = getRefreshToken();
    return {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
  }

let header_noAuth = {'Content-Type': 'application/json' }


// 响应拦截器
axios.interceptors.response.use(
    response => {
        // 对响应数据做些什么
        const responseData = response.data;
        if (responseData.code === 401) {
            // 如果返回数据中指示登录过期或未授权，重定向到登录页面

            // 清除本地存储信息
            localStorage.removeItem('access_token')
            localStorage.removeItem('userinfo')
            localStorage.removeItem('refresh')

            const currentRoute = router.currentRoute;

            // 判断当前路由是否是特定页面的路由
            if (currentRoute.path !== '/'){      
                router.push('/login');

                Message({
                    message: '登录过期，请重新登录',
                    type: 'warning',
                  });
            }

            

            return Promise.reject(responseData); // 可以选择是否reject该响应，视情况而定
        }
        return response;
    },
    error => {
        // 对响应错误做些什么
        return Promise.reject(error);
    }
);

// 请求前header加session_id
axios.interceptors.request.use(
    (config) => {
      const session_id = localStorage.getItem('s_id');
      if (session_id) {
        config.headers['Session-ID'] = session_id;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  


const apis = {
    login(data){
        return axios.post(base.login_path,data,{
            headers:header_noAuth,
            timeout:10000,
        })
    },
    bd_chat_single(data){
        return axios.post(base.bd_chat,data,{
            headers:getHeaders(),
            timeout:10000,
        })
    },
    azure_chat_single(data){
        return axios.post(base.azure_chat,data,{
            headers:getHeaders(),
            timeout:10000,
        })
    },
    grok_chat_single(data){
        return axios.post(base.grok_chat,data,{
            headers:getHeaders(),
            timeout:10000,
        })
    },
    aliyun_ds_v3_chat(data){
        return axios.post(base.aliyun_ds_v3_chat,data,{
            headers:getHeaders(),
            timeout:15000,
        })
    },
    yd_pic_translation_content(data){
        return axios.post(base.yd_pic_translation,data,{
            headers:getHeaders(),
            timeout:10000,
        })
    },
    yd_text_translation(data){
        return axios.post(base.yd_textranslation,data,{
            headers: getHeaders(),
            timeout:10000,
        })
    },
    bd_text_translations(data){
        return axios.post(base.bd_textTranslation,data,{
            headers:getHeaders(),
            timeout:10000
        })
    },
    bd_chat_single_translation(data){
        return axios.post(base.bd_chat_single,data,{
            headers:getHeaders(),
            timeout:10000
        })
    },
    ali_pic_translation_direct(data){
        return axios.post(base.ali_pics_translation,data,{
            headers:getHeaders(),
            timeout:30000
        })
    },
    register_user(data){
        return axios.post(base.register,data,{
            headers:header_noAuth,
            timeout:10000
        })
    },
    sendEmailCode(data){
        return axios.post(base.sendEmail,data,{
            headers:header_noAuth,
            timeout:10000
        })
    },
    forgetPassword(data){
        return axios.post(base.forgetPwd,data,{
            headers:header_noAuth,
            timeout:10000
        })
    },
    resetUserPassword(data){
        return axios.post(base.resetPwd,data,{
            headers:getHeaders(),
            timeout:10000
        })
    },
    createCharge(data){
        return axios.post(base.create_charge_qr_code,data,{
            headers:getHeaders(),
            timeout:10000
        })
    },
    queryTradeRes(data){
        return axios.post(base.query_trade_result,data,{
            headers:getHeaders(),
            timeout:10000
        })
    },
    getUserLoginStatus(data){
        return axios.get(base.get_user_login_status_path,{
            headers:getHeaders(),
            timeout:50000,
        })
    },
    getUserProfile(data){
        return axios.get(base.get_user_profile_path,{
            headers:getHeaders(),
            timeout:50000,
        })
    },
    getDyFansList(data){
        return axios.post(base.get_dy_fans_list_path,data,{
            headers:getHeaders(),
            timeout:20000
        })
    },
    getDyGroupMember(data){
        return axios.post(base.get_dy_group_member_path,data,{
            headers:getHeaders(),
            timeout:20000
        })
    },
    getDyGroupMemberQR(data){
        return axios.post(base.get_dy_group_member_qr_path,data,{
            headers:getHeaders(),
            timeout:20000
        })
    },
    getDyVideoComments(data){
        return axios.post(base.get_dy_video_comment_path,data,{
            headers:getHeaders(),
            timeout:20000
        })
    },
}

export default apis